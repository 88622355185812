import { phpApi } from '@/api/request'
import * as model from '../models'
// import * as common from '@/modules/common/models/index'


export function limitPurchaseList(params: object): Promise<model.LimitList> {
  return phpApi({
    method: 'GET',
    url: '/h5/limit/purchase/contents',
    params
  })
}