
























import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import * as api from '../api'
import * as model from '../models'
import { ListSell } from '@/modules/common/models'
import listSell from '@/modules/common/components/list-sell.vue'
import dsPagination from '@/modules/common/components/ds-pagination.vue'
import contentBox from '@/modules/common/components/content-box.vue'

@Component({
  components: {
    listSell,
    dsPagination,
    contentBox
  }
})
export default class limitList extends Vue {
  isLoading: boolean = true
  total: number = 0
  page: number = 1
  count: number = 10
  title: string = '拼团'
  limitList: Array<model.LimitList> = []
  mounted() {
    window.scrollTo(0, 0)
    this.renderLimitList()
  }

  get isHaveData() {
    return this.limitList.length
  }

  get getMoreTips() {
    return `全部${this.title ? this.title : '拼团'} (${this.total})`
  }

  @Watch('$route')
  onRouteChange(to: any, from: any) {
    this.renderLimitList()
  }

  renderLimitList() {
    this.page = Number(this.$route.query.page) || 1
    this.count = Number(this.$route.query.count) || 10
    this.title = this.$route.query.title && String(this.$route.query.title)
    this.getLimitList()
  }

  getLimitList() {
    this.isLoading = true
    api.limitPurchaseList({
      page: this.page,
      count: this.count
    }).then(res => {
      this.limitList = res.data
      this.total = res.page.total
      this.isLoading = false
    }).catch(res => {
      this.limitList = []
      this.total = 0
      this.isLoading = false
      console.error('getLimitList = ', res)
    })
  }

  getListSellItem(item: model.LimitList) {
    let listSellItem: ListSell = {
      content_id: item.content_id,
      type: item.type,
      title: item.title,
      brief: '',
      indexpic: item.indexpic,
      update_time: '',
      highest_price: Number(item.cost_price),
      lowest_price: Number(item.limit_price),
      subscribe_count: 0,
      original_price: ''
    }
    return listSellItem
  }

  onPageChange(val: number) {
    this.$router.push({
      name: 'LimitList',
      query: {
        page: `${val}`,
        count: `${this.count}`,
        title: `${this.title}`
      }
    })
  }
}
